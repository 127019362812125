<template>
  <div>
    <b-card no-body class="mb-0">
      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(name)="data">
          <b-icon
            v-b-tooltip.hover.top="data.item.status ? 'Ativo' : 'Inativo'"
            style="width: 9px;"
            icon="circle-fill"
            :class="data.item.status ? 'text-success' : 'text-danger'"
            :id="`product-row-${data.item.id}-status-icon`"
          />
          {{ data.item.name }}
        </template>

        <template #cell(actions)="data">
          <div class="form-row align-items-center justify-content-center">
            <div class="col-md-6 text-center">
              <feather-icon
                icon="TrashIcon"
                class="cursor-pointer cursor text-danger"
                size="16"
                @click="confirmDelete(data.item.uuid)"
              />
            </div>
            <div class="col-md-6 text-center">
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
                @click="modalEdit(data.item.uuid)"
              />
            </div>
          </div>
        </template>
      </b-table>
      
      <CustomPaginateTable :rowsTable="rowsTable" :currentPage="currentPage" :perPage="perPage" :totalRows="totalRows" />
          
      <b-modal
        id="createItem"
        modal-class="modal-create"
        :title="titleModal"
        centered
        hide-footer
        no-close-on-backdrop
      >
        <b-form @submit.prevent="submitCreate">
          <div class="form-row">
            <div class="col-md-9 col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Nome
                
              </label>
              <div class="form-group">
                <input
                  class="form-control"
                  v-model="item.name"
                  :class="{ 'is-invalid': $v.item.name.$error }"
                />
              </div>
            </div>
            <div class="col-md-3 col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Sigla                
              </label>
              <div class="form-group">
                <input
                  v-model="item.acronym"
                  class="form-control"
                  :class="{ 'is-invalid': $v.item.acronym.$error }"
                />
              </div>
            </div>
          </div>
          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-7 col-12">
              <button type="button" @click="$bvModal.hide('createItem');" class="btn btn-light float-left ">Fechar</button>
              <ButtonsActionsFooter variant="success" :submited="submited" :text="textSubmit" subtext="Aguarde..." />
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BTable, BButton, BPagination, VBTooltip,
  BModal, BFormGroup, BTooltip, BForm, BCardText, BFormRow, BContainer,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ButtonsActionsFooter from '@/views/components/button/ButtonsActionsFooter';
import { required } from "vuelidate/lib/validators";
import CustomPaginateTable from '@/views/components/custom/PaginationTable';

export default {
  components: {
    BCard,
    CustomPaginateTable,
    BModal,
    BFormGroup,
    BRow,
    BTable,
    BButton,
    BPagination,
    BTooltip,
    VBTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
  },
  beforeDestroy() {
    this.$root.$off('paginate:update');
  },
  data() {
    return {
      uuid: null,
      textSubmit: '',
      submited: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      tableColumns: [
      {
          key: 'name',
          label: 'Nome',
        },
        {
          key: 'type_name',
          label: 'Tipo',
          tdClass: 'text-center',
          thClass: 'text-center',
          thStyle: 'width: 180px',
        },
        {
          key: "actions",
          label: '',
          thClass: "text-center td-actions",
          tdClass: 'text-center',
        },
      ],
      dataItems: [],
      titleModal: '',
      item: {
        name: '',
        type: '',
        status: false,
      },
      optionsClassModalityTypes: [],
      optionsStatus: [
        {
          title: 'Ativo',
          code: 1,
        },
        {
          title: 'Inativo',
          code: 0,
        },
      ]
    };
  },
  validations: {
    item: {
      name: {
        required,
      },
      acronym: {
        required,
      },
    },
  },
  async mounted() {
    this.getData();
    this.$root.$on('open:sidebar', () => {
      this.textSubmit = 'Cadastrar';
      this.titleModal = 'Cadastrar';
      this.$bvModal.show('createItem');
    });
  },
  beforeDestroy() {
    this.$root.$off('open:sidebar');
  },
  methods: {
    modalEdit(uuid) {
      this.textSubmit = 'Salvar';
      this.titleModal = 'Editar';
      this.clearForm();
      this.$store
      .dispatch('ClassModality/show', uuid)
      .then((data) => {
        this.item = data.data;
        this.uuid = this.item.uuid;
        this.$bvModal.show('createItem');
      });
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error)
      {
        const data = {
          name: this.item.name,
          status: this.item.status.code,
          type_uuid: this.item.type.code,
        }

        if (this.textSubmit === 'Cadastrar')
        {
          this.$store.dispatch('ClassModality/create', data)
          .then(() => {
            this.notifyDefault('success');
            this.getData();
            this.$nextTick(() => {
              this.$bvModal.hide('createItem');
              this.clearForm();
            })
          })
          .finally(() => {
            this.submited = false;
          });
        } else {
          this.$store.dispatch('ClassModality/update', { uuid: this.uuid, data: data })
          .then(() => {
            this.getData();
            
            this.$nextTick(() => {
              this.$bvModal.hide('createItem');
              this.clearForm();
            })
          })
          .catch(() => {
            this.submited = false;
          })
          .finally(() => {
            this.submited = false;
          });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", 'Verifique os campos obrigatórios');
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    clearForm() {
      this.item = {
        name: '',
        active: false,
      }
    },
    async getData() {
      this.$store
        .dispatch('ClassModality/all', {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.currentPage = data.meta.current_page;
          }
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: 'Quer excluir?',
        text: 'Esta ação será irreversível!',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch("ClassModality/delete", uuid)
            .then(() => {
              this.getData();
              this.$swal({
                icon: 'success',
                title: 'Deletado!',
                text: 'Operação realizada com sucesso.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
};
</script>
